import axios, { AxiosInstance, AxiosError, InternalAxiosRequestConfig } from "axios";
import { useNavigate } from "react-router";

let isRefreshing = false;
let failedQueue: Array<{
  resolve: (token: string) => void;
  reject: (error: any) => void;
}> = [];

// Store CSRF token in memory
let csrfToken = '';

const processQueue = (error: any = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(csrfToken);
    }
  });
  failedQueue = [];
};

const refreshCSRFToken = async (): Promise<string> => {
  try {
    const response = await fetch(`${window.location.origin}/api/v1/csrf-token`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
      }
    });

    if (!response.ok) {
      throw new Error('Failed to refresh CSRF token');
    }

    const data = await response.json();
    csrfToken = data.csrfToken;
    return csrfToken;
  } catch (error) {
    throw error;
  }
};

interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean;
}

const createAxiosInstance = (baseURL: string): AxiosInstance => {
  const instance = axios.create({
    baseURL,
    withCredentials: true,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    adapter: axios.defaults.adapter!
  });

  // Request interceptor to add CSRF token to all requests
  instance.interceptors.request.use(
    async (config) => {
      // If we don't have a token yet, fetch one
      if (!csrfToken) {
        // console.log('No CSRF token found, fetching initial token...');
        try {
          await refreshCSRFToken();
        } catch (error) {
          // console.error('Failed to get initial CSRF token:', error);
          return Promise.reject(error);
        }
      }
      
      if (csrfToken) {
        config.headers['X-CSRF-Token'] = csrfToken;
        // console.log('Adding CSRF token to request:', csrfToken);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Response interceptor to handle token refresh
  instance.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
      const originalRequest = error.config as CustomAxiosRequestConfig;

      if (!originalRequest) {
        return Promise.reject(error);
      }

      // Handle CSRF token expiration (assuming server returns 419 status code)
      if (error.response?.status === 419) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers["X-CSRF-Token"] = token;
              return instance(originalRequest);
            })
            .catch((err) => Promise.reject(err));
        }

        originalRequest._retry = true;
        isRefreshing = true;

        try {
          const newToken = await refreshCSRFToken();
          instance.defaults.headers.common["X-CSRF-Token"] = newToken;
          originalRequest.headers["X-CSRF-Token"] = newToken;
          processQueue();
          return instance(originalRequest);
        } catch (refreshError) {
          // console.error('Failed to refresh token:', refreshError);
          processQueue(refreshError);
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
        }
      }

      // Handle unauthorized access
      if (error.response?.status === 401) {
        const navigate = useNavigate();
        navigate("/auth/login");
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

const AxiosClient = createAxiosInstance(`${window.location.origin}/api/v2/`);
export const ApiV1 = createAxiosInstance(`${window.location.origin}/api/v1/`);

export default AxiosClient;
